import React from 'react';
import PolicyTable from "./PolicyTable";
import Policy from "./Policy";
import PolicyEdit from "./PolicyEdit";
import AmendmentEdit from "./AmendmentEdit";
import AmendmentNew from "./AmendmentNew";
import PolicySearch from "./PolicySearch";
import PolicyToolbar from "./PolicyToolbar";
import About from "./About";

import { Route } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useAuth0 } from '@auth0/auth0-react';

const styles = theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#e0e0e0',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflow: 'auto',
        backgroundColor: '#e0e0e0'
    }})

const EditOptions = () => {
    const { isAuthenticated } = useAuth0();

// Hiding these doesn't do that much given the Javascript is all there for someone to look at, but
// it avoids potential confusion if something goes wrong.
    return (isAuthenticated ?
    <React.Fragment>
      <Route path="/policy/edit/:policyid" component={PolicyEdit} />
      <Route path="/amendment/edit/:itemid/:amendmentid" component={AmendmentEdit} />
      <Route path="/amendment/new/:itemid" component={AmendmentNew} />
    </React.Fragment> : null );
}

class PolicyPortal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false,
                       selected: null }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline />
                <PolicyToolbar />
                <main className={classes.content}>
                   <Route exact path="/" component={PolicyTable} />
                   <Route exact path="/about" component={About} />
                   <Route exact path="/policy/:policyid" component={Policy} />
                   <EditOptions />
                   <Route path="/search/:query" component={PolicySearch} />
                </main>
            </div>
        )
    }
}

PolicyPortal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PolicyPortal);