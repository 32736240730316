import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { api, version } from "./config";

const styles = theme => ({
    About: {
        padding: '12px',
        marginBottom: theme.spacing(3),
        width: '100%',
        maxWidth: '1000px',
        margin: 'auto'
    },
    toolbarTitle: {
        flex: 1,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '16px',
        fontWeight: 600
    },
    AboutText: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        marginBottom: '12px'
    }
})

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            api: 'Unknown',
            data: 'Unknown',
            portal: version
        };
        this.goBack = this.props.history.goBack.bind(this);
    }

    getData() {
        fetch(api + '/version')
            .then(res => {
                return res.json().then(version => {
                    this.setState(() => ({
                        api: version['api']
                    }));
                });
            });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const { classes } = this.props;

        return(
          <Paper className={classes.About}>
            <Toolbar className={classes.toolbarMain}>
              <Typography align="center" component="h1" color="inherit" noWrap className={classes.toolbarTitle}>About this site</Typography>
            </Toolbar>
            <Typography className={classes.AboutText}>This is not an official party site, and the author and maintainer is no longer a member of the Liberal
                Democrats or any other political party.</Typography>
            <Typography className={classes.AboutText}>However, the site aims to be a single repository for party policy for the Liberal Democrats. The
                inspiration comes from many questions seen on Facebook asking "what is party policy on...", which are hard to answer definitively without
                trawling through multiple agendas and other papers from party conferences. Full conference agendas, extras and dailies are only available
                online as far back as 2010 with some patchy documents dating from 2007 - if anyone has access to older material, it would be gratefully
                received. Similarly, any reports of errors or omissions would be welcome.</Typography>
            <Typography className={classes.AboutText}>This site currently only lists policy motions. Business motions and constitutional amendments
                are not included. The text of policy and spokespersons papers is also not yet available but should be at some future date.</Typography>
            <Typography className={classes.AboutText}>Privacy Policy: We currently collect and process only the publicily available names of those proposing
                motions and amendments at Liberal Democrat conferences. No further information than that publicly available is held. We use the information
                only for the purposes of reporting on the policies of the Liberal Democrats. Under the Data Protection Act 2018, the lawful bases we rely on
                for processing this information are archiving in the public interest and a legitimate interest.</Typography>
            <Typography className={classes.AboutText}>Portal version: {this.state.portal}</Typography>
            <Typography className={classes.AboutText}>API version: {this.state.api}</Typography>
          </Paper>
        )
    }
}

About.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(About));