import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { api } from "./config";

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        maxWidth: '1400px',
        margin: 'auto'
    },
    table: {
        minWidth: 700
    },
    itemCell: {
        minWidth: 200,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300,
    },
    titleCell: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 400,
    },
    headCell: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
    }
})

const heads = [
  { id: 'item', label: 'Item' },
  { id: 'title', label: 'Title' },
  { id: 'result', label: 'Result' }
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class PolicyTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          {heads.map(
            row => (
              <TableCell
                key={row.id}
                className={classes.headCell}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

PolicyTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

PolicyTableHead = withStyles(styles)(PolicyTableHead);

class PolicyTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [],
                       hasError: false,
                       order: 'desc',
                       orderBy: 'item',
                       selected: null }
        this.selectMotion = this.selectMotion.bind(this);
    }

    componentDidMount() {
        this.getData();
        document.title = "LibDem Policy Portal";
        document.description = "Liberal Democrat Policy and Debate Archive by Zoe O'Connell";
    }

    getData() {
        fetch(api + '/debate/')
            .then(res => {
                return res.json().then(policy => {
                    this.setState(() => ({
                        data: policy
                    }));
                });
            })
            .catch(err => {
                this.setState(() => ({ error: err }));
            });
    }

    selectMotion(id) { this.props.history.push('/policy/' + id); };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    // static getDerivedStateFromError(error) {
    //     // Update state so the next render will show the fallback UI.
    //     return { hasError: true };
    // }

    render() {
        const { classes } = this.props;
        // if (this.state.hasError) {
        //     return <div>Something went wrong</div>
        // }

        // TODO: Proper mouseover on clickable links
        return (
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <PolicyTableHead order={this.state.order} orderBy={this.state.orderBy} onRequestSort={this.handleRequestSort} />
                <TableBody>
                  {stableSort(this.state.data, getSorting(this.state.order, this.state.orderBy))
                    .map(row => {
                    return (
                    <TableRow key={row.item} hover onClick={() => this.selectMotion(row.item)} >
                      <TableCell className={classes.itemCell}>{row.conference} {row.code}</TableCell>
                      <TableCell className={classes.titleCell}>{row.title}</TableCell>
                      <TableCell className={classes.itemCell}>{row.checked ? row.result : "Not Checked"}</TableCell>
                    </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
        )
    }
}

PolicyTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(PolicyTable));