import PolicyText from './PolicyText';
import PolicyMetadata from './PolicyMetadata';
import Amendment from './Amendment';

import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import MoreIcon from '@material-ui/icons/MoreVert';

import { Auth0Context } from "@auth0/auth0-react";

import { api } from "./config";

const styles = theme => ({
    Policy: {
        padding: '12px',
        marginBottom: theme.spacing(3),
        width: '100%',
        maxWidth: '800px',
        margin: 'auto'
    },
    toolbarTitle: {
        flex: 1,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '16px',
        fontWeight: 600
    },
    warning: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#ff4040',
        width: '100%',
        marginTop: '4px'
    },
    passed: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#40ff40',
        width: '100%',
        marginTop: '4px'
    },
    unknown: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#ffff40',
        width: '100%',
        marginTop: '4px'
    },
    icon: {
        marginRight: '4px'
    },
    tab: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
    },
    tabbar: {
        marginBottom: '12px'
    },
    tabindicator: {
        backgroundColor: '#FAA61A'
    }
})

class HealthWarning extends React.Component {
    render () {
        const { classes, checked } = this.props;
        if (checked) {
            return null
        }

        return ( <Typography className={classes.warning}><Icon className={classes.icon}><WarningIcon /></Icon> This content was autogenerated from the agenda and has not yet been checked by a human.</Typography>)
    }
}

HealthWarning = withStyles(styles)(HealthWarning);

class PolicyStatus extends React.Component {
    render () {
        const { classes, result } = this.props;
        if (typeof(result) === 'undefined') {
            return ( <Typography className={classes.unknown}><Icon className={classes.icon}><ErrorIcon /></Icon> We do not know if this motion was passed by conference. It may not be policy.</Typography>)
        } else if (result) {
            return ( <Typography className={classes.passed}><Icon className={classes.icon}><DoneIcon /></Icon> Passed as policy by conference</Typography>)
        } else {
            return ( <Typography className={classes.warning}><Icon className={classes.icon}><ErrorIcon /></Icon> Not passed as policy</Typography>)
        }
    }
}

PolicyStatus = withStyles(styles)(PolicyStatus);

class Policy extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);
        this.state = { xml: null,
                       tab: 2,
                       lineNumbers: true,
                       openMenu: null,
                       expandedMetadataDropbox: false,
                       amendments: [] }
        this.closeMotion = this.closeMotion.bind(this);
        this.tabChange = this.tabChange.bind(this);
        this.toggleLineNumbers = this.toggleLineNumbers.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.policyEdit = this.policyEdit.bind(this);
        this.amendmentNew = this.amendmentNew.bind(this);
    }

    componentDidMount() {
        this.getData();
        this.getAmendments();
    }

    getData() {
        if (this.props.match.params.policyid != null) {
            fetch(api + '/debate/' + this.props.match.params.policyid)
                .then(res => {
                    return res.json().then(policy => {
                        const parser = new DOMParser();
                        var state = {
                            policytext: policy.text,
                            title: policy.title,
                            result: policy.result,
                            checked: policy.checked,
                            xml: parser.parseFromString(policy.text,"text/xml")
                        };
                        const metadataitems = ['code', 'title', 'submittedby', 'mover', 'summation', 
                                               'start_time', 'finish_time', 'briefing', 'chair', 'aide',
                                               'hallaide', 'applicability'];
                        for (var i in metadataitems) {
                            if (metadataitems[i] in policy) { 
                                state[metadataitems[i]] = policy[metadataitems[i]]
                            };
                        };
                        this.setState(state);
                        document.title = policy.title;
                    });
                })
                .catch(err => {
                    this.setState(() => ({ error: err }));
                });
        }
    }

    getAmendments() {
        if (this.props.match.params.policyid != null) {
            fetch(api + '/amendments/' + this.props.match.params.policyid)
                .then(res => {
                    return res.json().then(amendments => {
                        const parser = new DOMParser();
                        var state = {
                            amendments: amendments.map(row => { return (
                                { id: row.amendment_id,
                                  xml: parser.parseFromString(row.amendment_text,"text/xml"),
                                  submittedby: row.submittedby,
                                  mover: row.mover,
                                  summation: row.summation,
                                  name: row.name,
                                  result: row.result })})
                        };
                        // const metadataitems = ['code', 'title', 'submittedby', 'mover', 'summation', 
                        //                        'start_time', 'finish_time', 'briefing', 'chair', 'aide',
                        //                        'hallaide', 'applicability'];
                        // for (var i in metadataitems) {
                        //     if (metadataitems[i] in policy) { 
                        //         state[metadataitems[i]] = policy[metadataitems[i]]
                        //     };
                        // };
                        this.setState(state);
                    });
                })
                .catch(err => {
                    this.setState(() => ({ error: err }));
                });
        }
    }

    closeMotion() {
        this.props.history.goBack();
    };

    tabChange(event, value) {
        this.setState({ tab: value });
    };

    toggleLineNumbers(event) {
        this.setState({ lineNumbers: !this.state.lineNumbers,
                         openMenu: null })
    };

    openMenu(event) { this.setState({ openMenu: event.currentTarget }); };
    closeMenu() { this.setState({ openMenu: null }); };
    policyEdit() { this.props.history.push('/policy/edit/' + this.props.match.params.policyid); };
    amendmentNew() { this.props.history.push('/amendment/new/' + this.props.match.params.policyid); };

    render() {
        const { classes } = this.props;
        if (!this.state.xml) {
            return (
                <Paper className={classes.Policy}>No motion selected</Paper>
            )
        }

        const { isAuthenticated } = this.context;

        return(
          <React.Fragment>
              <Paper className={classes.Policy}>
                <Toolbar className={classes.toolbarMain}>
                  <IconButton onClick={this.closeMotion}><ArrowBackIcon /></IconButton>
                  <Typography align="center" component="h1" color="inherit" noWrap className={classes.toolbarTitle}>{this.state.title}</Typography>
                  { isAuthenticated ?
                    <React.Fragment>
                    <IconButton aria-label="More" aria-owns={this.state.openMenu ? 'more-menu' : undefined} aria-haspopup="true" onClick={this.openMenu}><MoreIcon /></IconButton>
                    <Menu id="more-menu" anchorEl={this.state.openMenu} open={Boolean(this.state.openMenu)} onClose={this.handleClose}>
                        { /* <MenuItem onClick={this.toggleLineNumbers}>
                            { (this.state.lineNumbers &&  "Hide") || "Show" } Line Numbers
                        </MenuItem> */ }
                        <MenuItem onClick={this.policyEdit}>Edit Motion</MenuItem>
                        <MenuItem onClick={this.amendmentNew}>Add Amendment</MenuItem>
                    </Menu>
                    </React.Fragment> : null }
                </Toolbar>
                <Tabs value={this.state.tab} onChange={this.tabChange} centered classes={{ indicator: classes.tabindicator, root: classes.tabbar }}>
                  <Tab label="Original Motion" classes={{ root: classes.tab }} />
                  <Tab label="Amendments" classes={{ root: classes.tab }} />
                  <Tab label="Motion as Passed" classes={{ root: classes.tab }} />
                </Tabs>
                <PolicyMetadata data={this.state} />
                <HealthWarning checked={this.state.checked} />
                <PolicyStatus result={this.state.result} />
                <PolicyText xml={this.state.xml} tab={this.state.tab} lineNumbers={this.state.lineNumbers} />
              </Paper>
              {this.state.amendments.map(row => { return (<Amendment amendment={row} itemid={this.props.match.params.policyid} />) })}
          </React.Fragment>
        )
    }
}

Policy.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Policy);