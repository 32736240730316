import PolicyText from './PolicyText';
import { api } from "./config";

import React, { useContext, useEffect, useState, useReducer } from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

import { useAuth0 } from "@auth0/auth0-react";

import AceEditor from 'react-ace';
import 'brace/mode/xml';
import 'brace/theme/monokai';

const styles = theme => ({
    AmendmentEdit: {
        padding: '12px',
        marginBottom: theme.spacing(3),
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto'
    },
    toolbarTitle: {
        flex: 1,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '16px',
        fontWeight: 600
    },
    submittedBy: {
        fontStyle: 'italic',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300
    },
    mover: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300
    },
    conference: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 400
    },
    warning: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#ff4040',
        width: '100%',
        marginBottom: '4px'
    },
    passed: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#40ff40',
        width: '100%',
        marginBottom: '4px'
    },
    unknown: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#ffff40',
        width: '100%',
        marginBottom: '4px'
    },
    icon: {
        marginRight: '4px'
    },
    tab: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
    },
    tabbar: {
        marginBottom: '12px'
    },
    tabindicator: {
        backgroundColor: '#FAA61A'
    },
    buttonWrapper: { 
        margin: '1px',
        position: 'relative',
        textAlign: 'center'
    },
    saveButton: {
        fontFamily: '"Open Sans", sans-serif',
        marginTop: '16px',
        marginBottom: '16px',
        position: 'flex',
        '&:hover': {
            backgroundColor: '#FAA61A'
        }
    },
    saveIcon: {
        marginLeft: '8px'
    },
    saveButtonProgress: {
        color: '#FAA61A',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    timeField: {
        marginRight: '12px'
    },
    formControl: {
        minWidth: 200
    }
})

const amendmentReducer = (state, action) => {
    var newState;

    switch (action.type) {
        case 'SET':
            return ( action.payload );   
        case 'UPDATE':
            newState = Object.assign({}, state);
            delete newState[action.payload.item];
            newState[action.payload.item] = action.payload.value;
            return ( newState );
        case 'UPDATE_XML':
            const parser = new DOMParser();
            newState = Object.assign({}, state);
            delete newState.xml;
            delete newState.amendment_text;
            newState.xml = parser.parseFromString(action.payload, "text/xml");
            newState.amendment_text = action.payload;
            return ( newState );
        default:
            return state;
    }
};

const initialState = { loaded: false };
const AmendmentContext = React.createContext(initialState);

function AmendmentEdit(props) {
    const [amendment, dispatch] = useReducer(amendmentReducer, initialState);
    return (
      <AmendmentContext.Provider value={{ amendment, dispatch }}>
          <AmendmentEditChild { ...props } />
      </AmendmentContext.Provider>
    )
}

function SaveButton(props) {
    const [ saving, setSaving ] = useState(false);
    const [ saveError, setSaveError ] = useState(false);
    const { classes } = props;
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { amendment } = useContext(AmendmentContext);

    async function saveData() {
        if (isAuthenticated && !saving) {
            setSaving(true);
            const accessToken = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: "write:policy" });
            var payload = JSON.stringify({
                    'amendment_text': amendment.amendment_text,
                    'name': amendment.name,
                    'result': amendment.result === 'passed' ? true :
                              amendment.result === 'notpassed' ? false : null,
                    'submittedby': 'submittedby' in amendment && amendment.submittedby !== '' ? amendment.submittedby : null,
                    'mover': 'mover' in amendment && amendment.mover !== '' ? amendment.mover : null,
                    'summation': 'summation' in amendment && amendment.summation !== '' ? amendment.summation : null
                });
            fetch(api + '/amendments/' + props.match.params.itemid + '/' + props.match.params.amendmentid, {
                    method: 'PUT',
                    headers: { 'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json' },
                    body: payload })
                .then(res => { if(!res.ok) { throw new Error('Submission Error'); }
                                setSaving(false);
                                setSaveError(false);})
                .catch(error => { console.log("Error");
                                setSaving(false);
                                setSaveError(true); });
        };
    }

    return (
        <div className={classes.buttonWrapper}>
            <Button variant="contained" className={classes.saveButton}
                onClick={saveData} disabled={saving}>
                Save
                {(saveError && <ReportProblemIcon className={classes.saveIcon} />)
                                        || <CloudUploadIcon className={classes.saveIcon} />}
            </Button>
            {saving ? <CircularProgress size={24} className={classes.saveButtonProgress} /> : null }
        </div>);
}

function AmendmentEditChild(props) {
    const [ lineNumbers, setLineNumbers ] = useState(true);
    const { classes } = props;
    const { amendment, dispatch } = useContext(AmendmentContext);

    useEffect(() => {
        async function fetchData() {
            const res = await fetch(api + '/amendments/' + props.match.params.itemid + '/' + props.match.params.amendmentid);
            const getAmendment = await res.json();
            console.log(getAmendment);
            const parser = new DOMParser();
            var newAmendment = {
                amendment_text: getAmendment.amendment_text,
                result: getAmendment.result,
                xml: parser.parseFromString(getAmendment.amendment_text,"text/xml"),
                loaded: true
            };

            if ('result' in getAmendment) {
                newAmendment['result'] = getAmendment.result ? 'passed' : 'notpassed';
            } else {
                newAmendment['result'] = 'unknown';
            }

            const metadataitems = ['name', 'submittedby', 'mover', 'summation'];

            for (var i in metadataitems) {
                if (metadataitems[i] in getAmendment) { 
                    newAmendment[metadataitems[i]] = getAmendment[metadataitems[i]]
                } else {
                    newAmendment[metadataitems[i]] = '';
                }
            }

            console.log(newAmendment);
            dispatch({ type: 'SET', payload: newAmendment });
        }

        if (amendment && !amendment.loaded && props.match.params.amendmentid) {
            fetchData();
        }
    }, [props.match.params.itemid, props.match.params.amendmentid, amendment, dispatch]);

    function closeAmendment() { props.history.goBack(); };

    function handleChange(event) {
        var name = 'id' in event.target ? event.target.id : event.target.name;
        dispatch({ type: 'UPDATE', payload: { item: name, value: event.target.value }});
    };

    function handleXmlChange(newValue) {
        dispatch({ type: 'UPDATE_XML', payload: newValue });
    }

    if (!amendment || !amendment.loaded) {
        return (<Paper className={classes.AmendmentEdit}>Loading...</Paper>);
    }

    if (!amendment.xml) {
        return null;
    }

    return(
          <Paper className={classes.AmendmentEdit}>
            <Toolbar className={classes.toolbarMain}>
              <IconButton onClick={closeAmendment}><ArrowBackIcon /></IconButton>
              <Typography align="center" component="h1" color="inherit" noWrap className={classes.toolbarTitle}>{amendment.name}</Typography>
            </Toolbar>
            <TextField id="name" label="Name" className={classes.textField} fullWidth
                       value={amendment.name} onChange={handleChange} margin="normal"/>
            <Select name="result" id="result" value={amendment.result} onChange={handleChange}>
                <MenuItem value={'unknown'}>Unknown</MenuItem>
                <MenuItem value={'passed'}>Passed</MenuItem>
                <MenuItem value={'notpassed'}>Not passed</MenuItem>
            </Select>
            <TextField id="submittedby" label="Submitted by" className={classes.textField} fullWidth
                       value={amendment.submittedby} onChange={handleChange} margin="normal"/>
            <TextField id="mover" label="Mover" className={classes.textField} fullWidth
                       value={amendment.mover} onChange={handleChange} margin="normal"/>
            <TextField id="summation" label="Summation" className={classes.textField} fullWidth
                       value={amendment.summation} onChange={handleChange} margin="normal"/>
            <AceEditor
                style={{width: '100%'}}
                mode="xml"
                theme="monokai"
                onChange={handleXmlChange}
                fontSize={12}
                showGutter={true}
                showPrintMargin={false}
                highlightActiveLine={true}
                debounceChangePeriod={250}
                value={String(amendment.amendment_text)}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{$blockScrolling: true}} />
            <SaveButton { ...props } />
            <PolicyText xml={amendment.xml} tab={0} lineNumbers={lineNumbers} />
          </Paper>
        )
}

AmendmentEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AmendmentEdit);