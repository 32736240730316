import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import PolicyPortal from './PolicyPortal';
import WebFont from 'webfontloader';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";

WebFont.load({
  google: {
    families: ['Open Sans:300,400,700', 'sans-serif']
  }
});

const Auth0ProviderWithHistory = ({ children }) => {
  // These are not secret, but we pull them from the environment to make changing them for
  // any reason easier.
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.href);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}>
      {children}
    </Auth0Provider>
  );
};

ReactDOM.hydrate(<Router location="/">
    <Auth0ProviderWithHistory>
      <PolicyPortal />
    </Auth0ProviderWithHistory>
  </Router>, document.getElementById('root'));
