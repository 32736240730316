import PolicyText from './PolicyText';

import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Button from '@material-ui/core/Button';

import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';

import { Auth0Context } from "@auth0/auth0-react";

const styles = theme => ({
    Amendment: {
        padding: '12px',
        marginBottom: theme.spacing(3),
        width: '100%',
        maxWidth: '800px',
        margin: 'auto'
    },
    amendmentDetails: {
        flexDirection: "column"
    },
    title: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600
    },
    warning: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#ff4040',
        width: '100%',
        marginTop: '4px'
    },
    passed: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#40ff40',
        width: '100%',
        marginTop: '4px'
    },
    unknown: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#ffff40',
        width: '100%',
        marginTop: '4px'
    },
    icon: {
        marginRight: '4px'
    },
    submittedBy: {
        marginTop: '6px',
        fontStyle: 'italic',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300,
        display: 'inline-block'
    },
    movers: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300,
        display: 'inline-block'
    },
    editButton: {
        fontFamily: '"Open Sans", sans-serif',
        marginRight: '16px',
        position: 'absolute',
        right: '0',
        '&:hover': {
            backgroundColor: '#FAA61A'
        }
    }
})

class AmendmentStatus extends React.Component {
    render () {
        const { classes, result } = this.props;
        if (typeof(result) === 'undefined') {
            return ( <Typography className={classes.unknown}><Icon className={classes.icon}><ErrorIcon /></Icon> We do not know if this amendment was passed.</Typography>)
        } else if (result) {
            return ( <Typography className={classes.passed}><Icon className={classes.icon}><DoneIcon /></Icon> Amendment passed</Typography>)
        } else {
            return ( <Typography className={classes.warning}><Icon className={classes.icon}><ErrorIcon /></Icon> Amendment not passed</Typography>)
        }
    }
}

AmendmentStatus = withStyles(styles)(AmendmentStatus);

class Amendment extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);
        this.state = { lineNumbers: true,
                       openMenu: null,
                       expandedDetailsDropbox: 'expand' in this.props }
        // this.toggleLineNumbers = this.toggleLineNumbers.bind(this);
        this.amendmentEdit = this.amendmentEdit.bind(this);
        this.expandDetailsDropbox = this.expandDetailsDropbox.bind(this);
    }

    expandDetailsDropbox() {
        this.setState({ expandedDetailsDropbox: !this.state.expandedDetailsDropbox });
    };


    // toggleLineNumbers(event) {
    //     this.setState({ lineNumbers: !this.state.lineNumbers,
    //                      openMenu: null })
    // };

    amendmentEdit() { this.props.history.push('/amendment/edit/' + this.props.itemid + '/' + this.props.amendment.id); };

    render() {
        const { classes } = this.props;

        if (!this.props.amendment.xml) {
            return null
        }

        const { isAuthenticated } = this.context;

        return(
          <Paper className={classes.Amendment}>
              <ExpansionPanel expanded={this.state.expandedDetailsDropbox} onChange={this.expandDetailsDropbox}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.title}>{this.props.amendment.name}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.amendmentDetails}>
                { isAuthenticated ?
                    <Button variant="contained" className={classes.editButton} onClick={this.amendmentEdit}>Edit</Button>
                    : null
                  }
                  { 'submittedby' in this.props.amendment && this.props.amendment['submittedby'] !== undefined ?
                      <Typography className={classes.submittedBy}>
                          Submitted by {this.props.amendment.submittedby}
                      </Typography> : '' }
                  { 'mover' in this.props.amendment && this.props.amendment['mover'] !== undefined ?
                      <Typography className={classes.movers}>
                          Mover: {this.props.amendment.mover}
                     </Typography> : '' }
                  { 'summation' in this.props.amendment && this.props.amendment['summation'] !== undefined ?
                      <Typography className={classes.movers}>
                          Summation: {this.props.amendment.summation}
                      </Typography> : '' }
                </ExpansionPanelDetails>
              </ExpansionPanel>
              { this.props.amendment['name'] !== 'Drafting Amendment' ? <AmendmentStatus result={this.props.amendment.result} /> : null }
              <PolicyText xml={this.props.amendment.xml} tab={0} lineNumbers={this.state.lineNumbers} />
          </Paper>
        )
    }
}

Amendment.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(Amendment));