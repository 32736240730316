import PolicyText from './PolicyText';
import PolicyMetadata from './PolicyMetadata';
import { api } from "./config";

import React, { useContext, useEffect, useState, useReducer } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreIcon from '@material-ui/icons/MoreVert';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';

import { useAuth0 } from "@auth0/auth0-react";

import AceEditor from 'react-ace';
import 'brace/mode/xml';
import 'brace/theme/monokai';

const styles = theme => ({
    PolicyEdit: {
        padding: '12px',
        marginBottom: theme.spacing(3),
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto'
    },
    toolbarTitle: {
        flex: 1,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '16px',
        fontWeight: 600
    },
    submittedBy: {
        fontStyle: 'italic',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300
    },
    mover: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300
    },
    conference: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 400
    },
    warning: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#ff4040',
        width: '100%',
        marginBottom: '4px'
    },
    passed: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#40ff40',
        width: '100%',
        marginBottom: '4px'
    },
    unknown: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        padding: '2px 2px 2px 2px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: '#ffff40',
        width: '100%',
        marginBottom: '4px'
    },
    icon: {
        marginRight: '4px'
    },
    tab: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
    },
    tabbar: {
        marginBottom: '12px'
    },
    tabindicator: {
        backgroundColor: '#FAA61A'
    },
    buttonWrapper: { 
        margin: '1px',
        position: 'relative',
        textAlign: 'center'
    },
    saveButton: {
        fontFamily: '"Open Sans", sans-serif',
        marginTop: '16px',
        marginBottom: '16px',
        position: 'flex',
        '&:hover': {
            backgroundColor: '#FAA61A'
        }
    },
    saveIcon: {
        marginLeft: '8px'
    },
    saveButtonProgress: {
        color: '#FAA61A',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    timeField: {
        marginRight: '12px'
    },
    formControl: {
        minWidth: 200
    }
})

const policyReducer = (state, action) => {
    var newState;

    switch (action.type) {
        case 'SET':
            return ( action.payload );   
        case 'UPDATE':
            newState = Object.assign({}, state);
            delete newState[action.payload.item];
            newState[action.payload.item] = action.payload.value;
            return ( newState );
        case 'UPDATE_XML':
            const parser = new DOMParser();
            newState = Object.assign({}, state);
            delete newState.xml;
            delete newState.policytext;
            newState.xml = parser.parseFromString(action.payload, "text/xml");
            newState.policytext = action.payload;
            return ( newState );
        default:
            return state;
    }
};

const initialState = { loaded: false };
const PolicyContext = React.createContext(initialState);

function PolicyEdit(props) {
    const [policy, dispatch] = useReducer(policyReducer, initialState);
    return (
      <PolicyContext.Provider value={{ policy, dispatch }}>
          <PolicyEditChild { ...props } />
      </PolicyContext.Provider>
    )
}
  
function SaveButton(props) {
    const [ saving, setSaving ] = useState(false);
    const [ saveError, setSaveError ] = useState(false);
    const { classes } = props;
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { policy } = useContext(PolicyContext);

    async function saveData() {
        if (isAuthenticated && !saving) {
            setSaving(true);
            const accessToken = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                                                               scope: "write:policy" });
            var payload = JSON.stringify({
                'text': policy.policytext,
                'title': policy.title,
                'result': policy.result === 'passed' ? true :
                          policy.result === 'notpassed' ? false : null,
                'resulttext': 'resulttext' in policy && policy.resulttext !== '' ? policy.resulttext : null,
                'checked': policy.checked,
                'submittedby': 'submittedby' in policy && policy.submittedby !== '' ? policy.submittedby : null,
                'mover': 'mover' in policy && policy.mover !== '' ? policy.mover : null,
                'summation': 'summation' in policy && policy.summation !== '' ? policy.summation : null,
                'start_time': 'start_time' in policy && policy.start_time !== '' ? policy.start_time : null,
                'finish_time': 'finish_time' in policy && policy.finish_time !== '' ? policy.finish_time : null,
                'briefing': 'briefing' in policy && policy.briefing !== '' ? policy.briefing : null,
                'chair': 'chair' in policy && policy.chair !== '' ? policy.chair : null,
                'aide': 'aide' in policy && policy.aide !== '' ? policy.aide : null,
                'hallaide': 'hallaide' in policy && policy.hallaide !== '' ? policy.hallaide : null,
                'applicability': 'applicability' in policy && policy.applicability !== '' ? policy.applicability : null
            });
            fetch(api + '/debate/' + props.match.params.policyid, {
                  method: 'PUT',
                  headers: { 'Authorization': 'Bearer ' + accessToken,
                             'Content-Type': 'application/json' },
                  body: payload })
                .then(res => { if(!res.ok) { throw new Error('Submission Error'); }
                               setSaving(false);
                               setSaveError(false);})
                .catch(error => { console.log("Error");
                                setSaving(false);
                                setSaveError(true); });
        };
    }

    return (
        <div className={classes.buttonWrapper}>
            <Button variant="contained" className={classes.saveButton}
                onClick={saveData} disabled={saving}>
                Save
                {(saveError && <ReportProblemIcon className={classes.saveIcon} />)
                                    || <CloudUploadIcon className={classes.saveIcon} />}
            </Button>
            {saving ? <CircularProgress size={24} className={classes.saveButtonProgress} /> : null}
        </div>);
}

function PolicyEditChild(props) {
    const [ openMenu, setOpenMenu ] = useState(false);
    const [ lineNumbers, setLineNumbers ] = useState(true);
    const [ tab, setTab ] = useState(0);
    const { classes } = props;
    const { policy, dispatch } = useContext(PolicyContext);

    useEffect(() => {
        async function fetchData() {
            const res = await fetch(api + '/debate/' + props.match.params.policyid);
            const getPolicy = await res.json();

            const parser = new DOMParser();
            var newPolicy = {
                policytext: getPolicy.text,
                title: getPolicy.title,
                result: getPolicy.result,
                checked: getPolicy.checked,
                xml: parser.parseFromString(getPolicy.text, "text/xml"),
                loaded: true
            };

            if ('result' in getPolicy) {
                newPolicy['result'] = getPolicy.result ? 'passed' : 'notpassed';
            } else {
                newPolicy['result'] = 'unknown';
            }

            const metadataitems = ['code', 'title', 'submittedby', 'mover', 'summation', 
                                    'start_time', 'finish_time', 'briefing', 'chair', 'aide',
                                    'hallaide', 'applicability'];

            for (var i in metadataitems) {
                if (metadataitems[i] in getPolicy) { 
                    newPolicy[metadataitems[i]] = getPolicy[metadataitems[i]]
                } else {
                    newPolicy[metadataitems[i]] = '';
                }
            }

            dispatch({ type: 'SET', payload: newPolicy });
        }

        if (policy && !policy.loaded && props.match.params.policyid) {
            fetchData();
        }
    }, [props.match.params.policyid, policy, dispatch]);


    function closeMotion() { props.history.goBack(); };

    function tabChange(event, value) { setTab(value); };

    function toggleLineNumbers() {
        setLineNumbers(!lineNumbers);
        setOpenMenu(null);
    };

    function doOpenMenu(event) { setOpenMenu(event.currentTarget); };
    // closeMenu() { this.setState({ openMenu: null }); };

    function handleChange(event) {
        var name = 'id' in event.target ? event.target.id : event.target.name;
        dispatch({ type: 'UPDATE', payload: { item: name, value: event.target.value }});
    };

    function handleCheckedChange(event) {
        dispatch({ type: 'UPDATE', payload: { item: 'checked', value: !policy.checked }} );
    }

    function handleXmlChange(newValue) {
        dispatch({ type: 'UPDATE_XML', payload: newValue });
    }

    if (!policy || !policy.loaded) {
        return (<Paper className={classes.PolicyEdit}>Loading...</Paper>);
    }

    if (!policy.xml) {
        return null;
    }

    return(
          <Paper className={classes.PolicyEdit}>
            <Toolbar className={classes.toolbarMain}>
              <IconButton onClick={closeMotion}><ArrowBackIcon /></IconButton>
              <Typography align="center" component="h1" color="inherit" noWrap className={classes.toolbarTitle}>{policy.title}</Typography>
              <IconButton aria-label="More" aria-owns={openMenu ? 'more-menu' : undefined} aria-haspopup="true" onClick={doOpenMenu}><MoreIcon /></IconButton>
              <Menu id="more-menu" anchorEl={openMenu} open={Boolean(openMenu)}>
                <MenuItem onClick={toggleLineNumbers}>
                    { (lineNumbers &&  "Hide") || "Show" } Line Numbers
                </MenuItem>
              </Menu>
            </Toolbar>
            <TextField id="title" label="Title" className={classes.textField} fullWidth
                       value={policy.title} onChange={handleChange} margin="normal"/>
            <TextField id="resulttext" label="Result" className={classes.textField} fullWidth
                       value={policy.resulttext} onChange={handleChange} />
            <Select name="result" id="result" value={policy.result} onChange={handleChange}>
                <MenuItem value={'unknown'}>Unknown</MenuItem>
                <MenuItem value={'passed'}>Passed</MenuItem>
                <MenuItem value={'notpassed'}>Not passed</MenuItem>
            </Select>
            <TextField id="chair" label="Chair" className={classes.textField} fullWidth
                       value={policy.chair} onChange={handleChange} margin="normal"/>
            <TextField id="aide" label="Aide" className={classes.textField} fullWidth
                       value={policy.aide} onChange={handleChange} margin="normal"/>
            <TextField id="hallaide" label="Hall Aide" className={classes.textField} fullWidth
                       value={policy.hallaide} onChange={handleChange} margin="normal"/>
            <TextField id="start_time" label="Start Time" className={classes.timeField}
                       value={policy.start_time} onChange={handleChange} margin="normal"
                       helperText="2018-01-31T09:00:00" />
            <TextField id="finish_time" label="Finish Time" className={classes.timeField}
                       value={policy.finish_time} onChange={handleChange} margin="normal"/>
            <TextField id="submittedby" label="Submitted by" className={classes.textField} fullWidth
                       value={policy.submittedby} onChange={handleChange} margin="normal"/>
            <TextField id="mover" label="Mover" className={classes.textField} fullWidth
                       value={policy.mover} onChange={handleChange} margin="normal"/>
            <TextField id="summation" label="Summation" className={classes.textField} fullWidth
                       value={policy.summation} onChange={handleChange} margin="normal"/>
            <TextField id="applicability" label="Applicability" className={classes.textField}
                       fullWidth multiline variant="outlined"
                       value={policy.applicability} onChange={handleChange} margin="normal"/>
            <TextField id="briefing" label="Briefing" className={classes.textField}
                       fullWidth multiline variant="outlined"
                       value={policy.briefing} onChange={handleChange} margin="normal"/>
            <FormControlLabel
                control={ <Switch checked={policy.checked} onChange={handleCheckedChange} id="checked" /> }
                label="Data Checked" />
            <PolicyMetadata data={policy} expand={true} />
            <AceEditor
                style={{width: '100%'}}
                mode="xml"
                theme="monokai"
                onChange={handleXmlChange}
                fontSize={12}
                showGutter={true}
                showPrintMargin={false}
                highlightActiveLine={true}
                debounceChangePeriod={250}
                value={String(policy.policytext)}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{$blockScrolling: true}} />
            <SaveButton { ...props } />
            <Tabs value={tab} onChange={tabChange} centered classes={{ indicator: classes.tabindicator, root: classes.tabbar }}>
              <Tab label="Original Motion" classes={{ root: classes.tab }} />
              <Tab label="Amendments" classes={{ root: classes.tab }} />
              <Tab label="Motion as Passed" classes={{ root: classes.tab }} />
            </Tabs>
            <PolicyText xml={policy.xml} tab={tab} lineNumbers={lineNumbers} />
          </Paper>);
};

PolicyEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(PolicyEdit));