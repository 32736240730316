import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const months = ['January', 'February', 'March', 'April', 'May',
                'June', 'July', 'August', 'September', 'October',
                'November', 'December'];
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday',
              'Saturday', 'Sunday'];

const styles = theme => ({
    title: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600
    },
    dateTime: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 400
    },
    chairs: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300
    },
    submittedBy: {
        marginTop: '6px',
        fontStyle: 'italic',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300,
        display: 'inline-block'
    },
    movers: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300,
        display: 'inline-block'
    },
    applicability: {
        marginTop: '6px',
        fontStyle: 'italic',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300,
        display: 'inline-block'
    },
    backgroundBriefingTitle: {
        marginTop: '8px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 600,
        display: 'inline-block'
    },
    backgroundBriefing: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300,
        display: 'inline-block'
    },
    metadataDetails: {
        flexDirection: "column"
    }
});

function zeroPad(input) {
    if (input.toString().length === 1) {
        return '0' + input.toString();
    }
    return input;
};

class PolicyMetadata extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expandedMetadataDropbox: 'expand' in this.props }
        this.expandMetadataDropbox = this.expandMetadataDropbox.bind(this);
    }

    expandMetadataDropbox() {
        this.setState({ expandedMetadataDropbox: !this.state.expandedMetadataDropbox });
    };

    render() {
        const { classes } = this.props;

        if ('start_time' in this.props.data) {
            var start = new Date(this.props.data['start_time']);
            var finish = new Date(this.props.data['finish_time']);
        }

        return (
        <ExpansionPanel expanded={this.state.expandedMetadataDropbox} onChange={this.expandMetadataDropbox}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.title}>{this.props.data.code} {this.props.data.title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.metadataDetails}>
            { 'start_time' in this.props.data && this.props.data['start_time'] !== '' ?
                <Typography className={classes.dateTime}>
                    {zeroPad(start.getHours()) + '.' + zeroPad(start.getMinutes()) +
                     (('finish_time' in this.props.data  && this.props.data['finish_time'] !== '') ? '-' + zeroPad(finish.getHours()) + '.' + zeroPad(finish.getMinutes()) : ' ') + ' ' +
                     days[start.getDay()] + ' ' + start.getDate() + ' ' + months[start.getMonth()] + ' ' + start.getFullYear()}
                </Typography> : '' }
            { 'chair' in this.props.data && this.props.data['chair'] !== '' ?
                <Typography className={classes.chairs}>
                    Chair: {this.props.data.chair}.
                    { 'aide' in this.props.data && this.props.data['aide'] !== '' ? ' Aide: ' + this.props.data.aide + '.' : '' }
                    { 'hallaide' in this.props.data && this.props.data['hallaide'] !== '' ? ' Hall Aide: ' + this.props.data.hallaide + '.' : '' }
                </Typography> : '' }
            { 'submittedby' in this.props.data && this.props.data['submittedby'] !== '' ?
                <Typography className={classes.submittedBy}>
                    Submitted by {this.props.data.submittedby}
                </Typography> : '' }
            { 'mover' in this.props.data && this.props.data['mover'] !== '' ?
                <Typography className={classes.movers}>
                    Mover: {this.props.data.mover}
                </Typography> : '' }
            { 'summation' in this.props.data && this.props.data['summation'] !== '' ?
                <Typography className={classes.movers}>
                    Summation: {this.props.data.summation}
                </Typography> : '' }
            { 'applicability' in this.props.data && this.props.data['applicability'] !== '' ?
                <Typography className={classes.applicability}>
                    Applicability: {this.props.data.applicability}
                </Typography> : '' }
            { 'briefing' in this.props.data && this.props.data['briefing'] !== '' ?
                <React.Fragment>
                    <Typography className={classes.backgroundBriefingTitle}>Background Briefing</Typography>
                    <Typography className={classes.backgroundBriefing}>
                        { this.props.data.briefing} 
                    </Typography>
                </React.Fragment> : '' }
          </ExpansionPanelDetails>
        </ExpansionPanel>
        );
    }
}

PolicyMetadata.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PolicyMetadata);