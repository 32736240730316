import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    insert: {
        borderBottom: '1px dotted green',
        color: 'green'
    },
    delete: {
        borderBottom: '1px dotted red',
        color: 'red',
        textDecoration: 'line-through',
        textDecorationColor: 'red'
    },
    hardpara: {
        marginBlockStart: '6px',
        marginBlockEnd: '6px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300
    },
    softpara: {
        marginBlockStart: '0px',
        marginBlockEnd: '0px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        fontWeight: 300
    }
})

function displayBlock(amendment, tab) {
    var a = amendment.attributes;
    if (tab === 0) {
        /* Original Motion - ignore amendments */
        return (a.getNamedItem("type").value === "delete")
    } else if (tab === 1) {
        /* Amendments - Display all amendments */
        return true;
    } else {
        /* Motion as passed */
        if (a.getNamedItem("type").value === "delete" && a.getNamedItem("result") && a.getNamedItem("result").value === 'defeated')
            return true;
        if (a.getNamedItem("type").value === "insert" && (!a.getNamedItem("result") || a.getNamedItem("result").value !== 'defeated'))
            return true;
        return false;
    }
}
function renderBlock(block, tab) {
    if (block instanceof Text) { return true }
    if (block.localName === 'amendment') {
        return (displayBlock(block, tab));
    } 
}

class Block extends React.Component {
    render () {
        const { classes, tab } = this.props;
        if (this.props.content instanceof Text) {
            return (this.props.content.textContent)
        } else if (this.props.content.localName === 'amendment' && displayBlock(this.props.content, tab)) {
            if (tab !== 1) {
                return <span>{this.props.content.textContent}</span>;
            } else {
                /* Highlight amendments */
                var a = this.props.content.attributes;
                var amendment = a.getNamedItem("number").value === 'D' ? "drafting amendment" :
                               (a.getNamedItem("number").value === 'S' ? "separate vote" :
                                'amendment ' + a.getNamedItem("number").value);
                if (a.getNamedItem("type").value === "insert") {
                    return (<Tooltip className={classes.insert} title={"Inserted by " + amendment}>
                                <span>{this.props.content.textContent}</span>
                            </Tooltip>);
                } else if (a.getNamedItem("type").value === "delete") {
                    return (<Tooltip className={classes.delete} title={"Deleted by " + amendment}>
                                <span>{this.props.content.textContent}</span>
                            </Tooltip>);
                }
            }
        }
        return null
    }
}

Block = withStyles(styles)(Block);

class Line extends React.Component {
    render() {
        var blocks = Array.from(this.props.content.childNodes);
        var b = 0;
        var a = this.props.content.attributes;
        if (blocks.length === 1 && !renderBlock(blocks[0], this.props.tab)) { return null }
        var content = blocks.map(block => <Block key={b++} content={block} tab={this.props.tab} />)
        var lineno = a.getNamedItem("number") ? a.getNamedItem("number").value : null;
        var block = a.getNamedItem("block") ? a.getNamedItem("block").value : null;

        if (this.props.lineNumbers) {
            if (this.props.indent && block) {
                return (<span style={{display: 'block'}}>
                            <span style={{display: 'inline-block', width: '64px'}}>{lineno}</span>
                            <span style={{display: 'inline-block', width: '32px'}}>{block}</span>
                            {content}
                        </span>)
            } else if (this.props.indent) {
                return (<span style={{display: 'block'}}><span style={{display: 'inline-block', width: '96px'}}>{lineno}</span>{content}</span>)
            } else if (this.props.noindent) {
                return (<span style={{display: 'block'}}><span style={{display: 'inline-block', width: '32px'}}>{lineno}</span>{content}</span>)
            } else if (block) {
                return (<span style={{display: 'block'}}>
                            <span style={{display: 'inline-block', width: '32px'}}>{lineno}</span>
                            <span style={{display: 'inline-block', width: '32px'}}>{block}</span>
                            {content}
                        </span>)
            } else {
                return (<span style={{display: 'block'}}><span style={{display: 'inline-block', width: '64px'}}>{lineno}</span>{content}</span>)
            }
        } else {
            return (<React.Fragment>{content} </React.Fragment>)
        }
    }
}

class Paragraph extends React.Component {
    render() {
        const { classes } = this.props;
        var lines = Array.from(this.props.content.getElementsByTagName("line"));
        var l = 0;
        var a = this.props.content.attributes;
        var c = (a.getNamedItem("type") && a.getNamedItem("type").value === 'soft') ? classes.softpara : classes.hardpara;
        var indent = (a.getNamedItem("indent") && a.getNamedItem("indent").value === 'extra')
        var noindent = (a.getNamedItem("indent") && a.getNamedItem("indent").value === 'none')
        return (<p className={c}>{lines.map(line => <Line key={l++} content={line} indent={indent} noindent={noindent} tab={this.props.tab} lineNumbers={this.props.lineNumbers} line={l}/>)}</p>)
    }
}

Paragraph = withStyles(styles)(Paragraph);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <React.Fragment>Something went wrong.</React.Fragment>;
    } else {
        return this.props.children; 
    }
  }
}

class PolicyText extends React.Component {
    render() {
        var paragraphs = this.props.xml ? Array.from(this.props.xml.getElementsByTagName("para")) : [];
        var p=1;

        return(
          <ErrorBoundary>
            <React.Fragment>
              {paragraphs.map(para => <Paragraph key={p++} content={para} tab={this.props.tab} lineNumbers={this.props.lineNumbers} />)}
            </React.Fragment>
          </ErrorBoundary>
        )
    }
}

PolicyText.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PolicyText);