import React from 'react';
import { withRouter, Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { useAuth0 } from "@auth0/auth0-react";

const styles = theme => ({
    toolbarMain: {
        borderBottom: '1px solid #808080',
        backgroundColor: '#FAA61A',
        color: '#000000'
    },
    toolbarButton: {
        fontFamily: '"Open Sans", sans-serif',
    },
    toolbarTitle: {
        flex: 1,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '20px',
        fontWeight: 600
    },
    searchInput: {
            marginLeft: 8,
            flex: 1,
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '14px',
            fontWeight: 300
    },
    tableContainer: {
        marginLeft: -22,
    }
})

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.node.isRequired
};

const AuthButton = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();

  if (isLoading) {
    return null ;
  }

  return (isAuthenticated ?
    <Button size="small" onClick={() => logout()}>Logout {user.name}</Button> :
    <Button size="small" onClick={() => loginWithRedirect({ appState: { returnTo: window.location.pathname },
                                                            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                                                            scope: "write:policy" })}>Login</Button>);
}

class PolicyToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.searchButton = this.searchButton.bind(this);
        this.state = {query: ''}
    }

    searchButton(query) { this.props.history.push('/search/' + this.state.query); };

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
            <ElevationScroll {...this.props}>
            <AppBar>
              <Toolbar className={classes.toolbarMain}>
                <Link to='/about' style={{ textDecoration: 'none' }}><Button size="small" className={classes.toolbarButton}>About</Button></Link>
                <AuthButton className={classes.toolbarButton} />
                <Link to='/' style={{ textDecoration: 'none', color: 'inherit', align: 'center', flex: 1 }}>
                    <Typography align="center" component="h1" color="inherit" noWrap className={classes.toolbarTitle}>Zoë O'Connell's LibDem Policy Portal</Typography>
                </Link>
                <Paper className={classes.search}>
                    <form onSubmit={() => this.searchButton()} noValidate autoComplete="off">
                        <InputBase className={classes.searchInput}
                                  placeholder="Search..."
                                  value={this.state.query}
                                  onChange={(event) => this.setState({ query: event.target.value })}/>
                        <IconButton type="submit" className={classes.iconButton} aria-label="Search">
                            <SearchIcon />
                        </IconButton>
                  </form>
                </Paper>
              </Toolbar>
            </AppBar>
            </ElevationScroll>
            <Toolbar />
            </React.Fragment>
        );
    }
}

PolicyToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(PolicyToolbar));